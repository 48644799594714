.container {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}
.row {
  border-bottom: solid;
  border-width: 1px;
  padding: 0px;
}
