.thumbnail {
  width: 75px;
  height: 75px;
  border-radius: 40px;
}

.textArea input {
  padding-left: 5px;
  padding-right: 5px;
}
.textArea {
  margin-right: 20px;
}
.listItem {
  border-bottom: solid;
  border-color: #c4c4c4;
  border-width: 1px;
  margin-bottom: 10px;
}
.itemAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.freeDelivery {
  margin-right: 5px;
}
.inputGrid {
  text-align: right;
  justify-content: right;
  align-items: right;
}
.liText {
  width: 160%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 900px) {
  .liText {
    width: 150%;
  }
}
@media only screen and (max-width: 650px) {
  .liText {
    width: 120%;
  }
}
@media only screen and (max-width: 430px) {
  .inputGrid {
    text-align: center;
  }
}
