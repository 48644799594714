.container {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.divCenter {
  vertical-align: middle;
  text-align: left;
  margin-top: 20px;
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: right;
}
.categoryHelper {
  color: red;
}
.returnButton {
  margin-left: 20px;
}
.submitButton Button {
  margin: 10px;
}
.previewPic {
  width: 30%;
  height: 30%;
}
.deletePic {
  position: absolute;
  top: -12px;
  right: 33%;
  color: #fff;
  background-color: #ff4081;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  font-weight: bold;
  line-height: 30px;
  width: 30px;
  height: 30px;
  padding: 0%;
  border-width: 0px;
}
.imgWrapper {
  position: relative;
  text-align: center;
}
.textFieldmargin {
  width: 100%;
}
