.container {
  text-align: center;
  margin-top: 50px;
}
.divText {
  text-align: center;
  margin-top: 20px;
}
.TextField {
  width: 80%;
}
.buttonGroup {
  margin-top: 10px;
}
.buttonGroup Button {
  margin: 5px;
}
