.container {
  text-align: center;
}
.buttonGroup {
  text-align: center;
  vertical-align: center;
  padding-top: 0px;
  width: 40%;
}
.buttonGroup Button {
  vertical-align: center;
  margin-top: 40px;
}
.pharagraphs {
  margin-top: 0px;
}

.importItem {
  border-bottom: 1px solid #c4c4c4;
}
.importItemUpdated {
  border-bottom: 1px solid #c4c4c4;
  display: block !important;
}
