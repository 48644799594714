.container {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  margin-bottom: 10vh;
}

.noResults {
  font-size: 20px;
  color: red;
}
